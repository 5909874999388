<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6" v-show="diller.length != 1">
          <b-form-group label="Dil">
            <v-select
              v-model="defaultDil"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" class="pt-3">
          <b-form-group label="Aciklama">
            <b-form-textarea
              size="lg"
              class="rounded-0"
              rows="2"
              ref="baslik"
              v-model="form.icerik[defaultDil].aciklama"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ColorPickers :label="'Renk Kodu'" :color="form.renk" @color="form.renk = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import ColorPickers from '@/components/colorPicker/ColorPickers.vue';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
export default defineComponent({
  components: {
    vSelect,
    ColorPickers,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const { updateData } = toRefs(props);
    expo.AnasayfaStatu = ref([
      {
        value: false,
        title: 'Hayır',
      },
      {
        value: true,
        title: 'Evet',
      },
    ]);

    expo.form = ref({
      k_no: null,
      icerik: {},
      renk: '#000000',
      statu: true,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('rezervasyonDurumGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            context.emit('updateRefresh', res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
