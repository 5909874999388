<template>
  <div class="list-card">
    <div
      class="list-card-left"
      :class="attrs['leftClass']"
      :style="attrs['leftStyle']"
    >
      <div class="list-card-header">
        <b-form-input
          class="search-input"
          v-model="search"
          placeholder="Ara..."
          :input="$emit('searchInput', search)"
          v-if="attrs['searchActive']"
        />
        <b-button
          variant="primary"
          class="rounded-0"
          @click="$emit('newAdd', true)"
          v-if="attrs['newAddActive']"
        >
          YENİ KAYIT
        </b-button>
      </div>
      <div class="list-card-body">
        <b-overlay
          id="overlay-background"
          :show="attrs['show']"
          variant="light"
          :opacity="0.8"
          blur="2px"
          rounded="sm"
        >
          <slot name="leftBody" />
        </b-overlay>
      </div>
    </div>
    <div class="list-card-right">
      <div class="list-card-overlay" />
      <div class="list-card-header">
        <div class="d-flex align-items-center">
          <i class="fad fa-bars fa-2x toggle-list-menu" />
          <h5>{{ attrs["title"] }}</h5>
        </div>
        <div>
          <span v-if="attrs['totalActive']"
            >Toplam Kayıt : {{ attrs["total"] }}</span
          >
          <b-button
            v-if="attrs['type']"
            squared
            variant="secondary"
            class="ml-4"
            @click="$emit('handlerClose', false)"
          >
            <i class="fad fa-times" />
          </b-button>
          <b-button
            v-if="attrs['newSetting']"
            squared
            variant="info"
            class="ml-4"
            @click="$emit('handlerSetting', true)"
          >
            <i class="fad fa-cogs" /> {{ attrs["newSettingTitle"] }}
          </b-button>
          <b-button
            v-if="attrs['newAddRightActive']"
            squared
            variant="info"
            class="ml-4"
            @click="$emit('newAdd', true)"
          >
            <i class="fad fa-plus" /> Yeni Kayıt
          </b-button>
          <b-button
            v-if="attrs['backToPage']"
            squared
            variant="danger"
            class="ml-2"
            @click="$emit('pageToPage', true)"
          >
            <i class="fad fa-arrow-left" /> Geri
          </b-button>
        </div>
      </div>
      <div class="list-card-body">
        <b-overlay
          id="overlay-background"
          :show="attrs['show']"
          variant="light"
          :opacity="0.8"
          blur="2px"
          rounded="sm"
        >
          <slot name="rightBody" />
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  defineComponent,
  useAttrs,
  onMounted,
} from "@vue/composition-api";

export default defineComponent({
  inheritAttrs: false,
  setup() {
    const expo = {};
    expo.attrs = useAttrs();
    expo.search = ref(null);

    onMounted(() => {
      const listCardLeft = document.querySelector(".list-card-left");
      const toggleMenu = document.querySelector(".toggle-list-menu");
      const listCardOverlay = document.querySelector(".list-card-overlay");

      toggleMenu.addEventListener("click", toogleNav);
      listCardOverlay.addEventListener("click", toogleNav);
      function toogleNav() {
        listCardOverlay.classList.toggle("active");
        listCardLeft.classList.toggle("open-menu");
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
